import LoginPage from '../components/LoginPage'
import Score from '../components/ScorePage'
import HomePage from '../components/HomePage'
// import Contact from './components/Contact.vue'
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const router = new Router({
  // mode: 'hash',
  // base: process.env.VUE_APP_BASE,
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: {
        keepAlive: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        keepAlive: true,
      },
    },
    {
      path: '/score',
      name: 'Score',
      component: Score,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home,
    // },
  ],
  // scrollBehavior(to, from, savedPosition) {
  //   // 滚动位置不对
  //   return savedPosition || { x: 0, y: 0 }
  // },
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  //  if (to.meta.title) {
  //    document.title = to.meta.title
  //  }
  console.log(to)
  next()
})

export default router
