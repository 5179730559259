<template>
  <div v-if="!isMobile" style="margin-left: 40px">
    <div class="flex justify-center" style="margin-left: -70px; margin-bottom: -20px;">
      <div class="group__title ml-0">
        2024年H1&nbsp;中国节能产品政府采购型号配置查询
      </div>
    </div>

    <el-form ref="form" :model="form" label-width="120px" class="flex  justify-center">
      <div>
        <div class=" text-sm font-thin text-opacity-10 ml-5 mb-8 text-left" style="width: 240px;">
          <span>
            Version:&nbsp;A &nbsp;&nbsp;&nbsp;&nbsp;Date:&nbsp;2024-09-08
          </span>
        </div>
        <div class="title_old">初始化</div>
        <div style="
          color: green;
          text-align: left;
          margin-left: 20px;
          margin-bottom: 10px;
          font-size: 18px;
        ">
          数据加载完成
        </div>
        <div class="title_old">查询</div>
        <el-form-item label="平台(工作页)" class="col-sm-10 font-bold">
          <div style="width: 550px; margin-left: -50px">
            <el-select v-model="model" placeholder="请选择" size="medium" class="el-input__text-left el-input__form">
              <el-option v-for="{ key, label, value } in modelsOptions" :key="key" :label="label" :value="value"
                style="text-align: left;" />
            </el-select>
          </div>
        </el-form-item>

        <table class="table_two" border="1px">
          <tr class="row1">
            <th class="th1">关键部位</th>
            <th class="th2">描述</th>
          </tr>
          <tr class="row2">
            <td class="th1">处理器</td>
            <td class="th2">
              <el-select v-model="cpuKey" placeholder="请选择" class="el-input__text-left table_selector">
                <el-option v-for="o in cpuOptions" :key="o.key" :label="o.label" :value="o.key"
                  style="text-align: left;" />
              </el-select>
            </td>
          </tr>
          <tr class="row2">
            <td class="th1">内存</td>
            <td class="th2">
              <el-select v-model="memKey" placeholder="请选择" class="el-input__text-left table_selector">
                <el-option v-for="o in memOptions" :key="o.key" :label="o.label" :value="o.key"
                  style="text-align: left;" />
              </el-select>
            </td>
          </tr>
          <tr class="row2">
            <td class="th1">第一硬盘</td>
            <td class="th2">
              <el-select v-model="hddKey" placeholder="请选择" class="el-input__text-left table_selector">
                <el-option v-for="o in hddOptions" :key="o.key" :label="o.label" :value="o.key"
                  style="text-align: left;" />
              </el-select>
            </td>
          </tr>

          <tr class="row2">
            <td class="th1">第二硬盘</td>
            <td class="th2">
              <el-select v-model="oddKey" placeholder="请选择" class="el-input__text-left table_selector">
                <el-option v-for="o in oddOptions" :key="o.key" :label="o.label" :value="o.key"
                  style="text-align: left;" />
              </el-select>
            </td>
          </tr>
          <tr class="row2">
            <td class="th1">显卡</td>
            <td class="th2">
              <el-select v-model="graphicKey" placeholder="请选择" class="el-input__text-left table_selector">
                <el-option v-for="o in graphicOptions" :key="o.key" :label="o.label" :value="o.key"
                  style="text-align: left;" />
              </el-select>
            </td>
          </tr>
        </table>
        <div>
          <div class="flex flex-row-reverse" style="width: 730px; margin-top: 20px">
            <el-button class="button_deepgrey" @click="showNormalTable">查询</el-button>
            <el-button style="margin-right: 5px;" class="button_grey" @click="reset">重置</el-button>
            <span v-text="errorMsg" class="errorInfo"></span>
          </div>
        </div>
        <div class="title_old" v-show="showSearchResult">查询结果</div>
        <table v-show="showSearchResult" class="table_two" border="0px">
          <tr class="row1">
            <th class="th2" style="width: 700px;">型号</th>
          </tr>
          <tbody>
            <tr class="row2" style="border-width: 0px;">
              <td>
                <span v-text="searchModel">

                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="title_old my-4" v-if="isAmin">管理功能</div>
        <div v-if="isAmin" class="w-3xl border-3 h-screen-sm"
          style="border-color: rgba(59, 130, 246, 0.1);margin-top: 30px;margin-bottom: 50px;">
          <div class="flex justify-evenly" style="margin-left: 0px;margin-top: 30px;">
            <div>
              <el-upload class="upload-demo" style="max-width: 200px" ref="upload" :action="url" accept=" .xlsx, .xlsm"
                :data="{ type: 'other' }" :on-preview="handlePreview" :on-remove="handleRemove"
                :before-remove="beforeRemove" multiple :limit="100" :on-exceed="handleExceed" :file-list="fileList">
                <el-button size="large">点击上传文件</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传xlsx/xlsm文件，且不超过10M
                </div>
              </el-upload>
            </div>

            <div>
              <el-button class="button_grey" style="margin-right: 5px; width: 180px"
                @click="clearData">清空数据(vs&nbsp;CQCCA)</el-button>
              <!-- <el-button class="button_grey" style="margin-right: 5px; width: 180px"
                @click="showDiffTable">显示差异(vs&nbsp;CQCCA)</el-button> -->
              <!-- <el-switch style="display: block; margin-top: 10px" v-model="sourceCNCA" active-color="#e0e0e0"
                inactive-color="#265a88" active-text="CNCA" inactive-text="CQCCA">
              </el-switch> -->
            </div>
            <!-- <div style="margin-left: 20px" class="flext justify-evenly">
              <el-button class="button_deepgrey" style="margin-left: 10px; width: 120px"
                @click="download">下载数据</el-button>
              <el-button class="button_deepgrey" style="margin-left: 5px; width: 120px"
                @click="downDiff">下载差异数据</el-button>
            </div> -->
          </div>
          <!-- <div style="padding-left: 0px;margin-top: 30px;">
            <el-table :data="tableData" class="order-table" style="width: 100%; margin: 0px auto; padding:10px;"
              max-height="500" header-row-class-name="table__header" :row-class-name="tableRowClassName">
              <el-table-column prop="id" label="id" min-width="20%" align="center">
              </el-table-column>
              <el-table-column prop="skuName" label="sku名称" min-width="80%" align="center">
              </el-table-column>
            </el-table>
          </div> -->

        </div>
      </div>
    </el-form>
  </div>
  <div v-else class="ml-10 mr-10 mt-6" style="">
    <div class="mb-2 text-xl font-semibold font-blue">
      2023年H2&nbsp;中国节能产品政府采购型号配置查询
    </div>
    <div class=" text-xs font-thin text-opacity-10 ml-0 mb-2" style="width: 180px;">
      <p class="overflow-visible whitespace-nowrap">
        Version: 1.0 Date: 2024-02-12
      </p>
    </div>
    <!-- <div class="flex flex-row flex-shrink-0"> -->
    <h2 class="text-left font-blue">平台</h2>
    <DropdownMenu class="ddm flex-grow">
      <DropdownItem v-model="model" :options="modelsOptions" />
    </DropdownMenu>
    <!-- </div> -->

    <h2 class="text-left font-blue">处理器</h2>
    <DropdownMenu class="ddm">
      <DropdownItem v-model="cpuKey" :disabled="cpuKey == ''" :options="cpuOptions" />
    </DropdownMenu>
    <h2 class="text-left font-blue">内存</h2>
    <DropdownMenu class="ddm">
      <DropdownItem v-model="memKey" :disabled="memKey == ''" :options="memOptions" />
    </DropdownMenu>
    <h2 class="text-left font-blue">第一硬盘</h2>
    <DropdownMenu class="ddm">
      <DropdownItem v-model="hddKey" :disabled="hddKey == ''" :options="hddOptions" />
    </DropdownMenu>
    <h2 class="text-left font-blue">第二硬盘</h2>
    <DropdownMenu class="ddm">
      <DropdownItem v-model="oddKey" :disabled="oddKey == ''" :options="oddOptions" />
    </DropdownMenu>
    <h2 class="text-left font-blue">显卡</h2>
    <DropdownMenu class="ddm ">
      <DropdownItem v-model="graphicKey" :disabled="graphicKey == ''" :options="graphicOptions" />
    </DropdownMenu>
    <el-button style="margin-right: 5px;" class="button_grey" @click="reset">重置</el-button>

    <el-button class="button_deepgrey mt-2 " @click="showDialog">查询</el-button>
  </div>
</template>

<script>
// import axios from "../lib/axios.js";
import axios from "axios";
import { config } from "../config.js";
import { downloadExcel } from "../lib/excel";
import DropdownMenu from 'vant/lib/dropdown-menu';
import 'vant/lib/dropdown-menu/style';
import DropdownItem from 'vant/lib/dropdown-item';
import 'vant/lib/dropdown-item/style';
import Dialog from 'vant/lib/dialog';
import 'vant/lib/dialog/style';
export default {
  components: {
    DropdownMenu,
    DropdownItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      titleModel: '请选择',
      titleCpu: '请选择',
      titleMem: '请选择',
      titleHdd: '请选择',
      titleOdd: '请选择',
      titleGraphic: '请选择',
      isMobile: false,
      value1: 0,
      value2: 'a',
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      fileList: [],
      url: "",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      modelsOptions: [],
      model: "",
      computers: [],
      cpus: new Set(),
      cpuOptions: [],
      cpuKey: "",
      hdds: new Set(),
      hddOptions: [],
      hddKey: "",
      odds: new Set(),
      oddOptions: [],
      oddKey: "",
      mems: new Set(),
      memOptions: [],
      memKey: "",
      graphics: new Set(),
      graphicOptions: [],
      graphicKey: "",
      modelMapping: {},
      tableData: [],
      showDiff: false,
      sourceCNCA: false,
      admin: false,
      errorMsg: '',
      searchModel: '',
      showSearchResult: false,
      isAmin: false,
    };
  },

  mounted() {
    this.isMobile = this.isMobileDevice()
    console.log('Home')
    if (this.isMobile) {
      // 手机端逻辑
      console.log("页面在手机上打开");
      //检查session
      const se = sessionStorage.getItem('tsa')
      if (!se) {
        console.log("无登录信息");

        this.$router
          .push({
            name: "Login",
          })
          .catch(() => { });
      }
    } else {
      // 电脑端逻辑
      console.log("页面在电脑端打开");
    }

    this.isAmin = this.$store.state.isAdmin
    this.url = config.host + "/api/upload";
    try {
      const api = `${config.host}/db/`;
      axios.get(api).then((response) => {
        // console.log(response);
        const data = response.data;
        const ms = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          ms.push({
            key: element.id,
            label: element.name,
            text: element.name,
            value: element.name,
            options: [],
          });
        }
        this.modelsOptions = null;
        this.modelsOptions = ms;
        // console.log(this.modelsOptions);
      });
    } catch (error) {
      console.log(error);
      this.$message.error(error);
    }
  },
  methods: {
    // changeModel(i) {
    //   console.log(this.modelsOptions)
    //   console.log(i)
    //   this.titleModel = i
    // },
    // changeCpu(i) {
    //   console.log(this.cpuOptions)
    //   console.log(i)
    //   this.titleCpu = i
    // },
    tableRowClassName({ row }) {
      if (row.id % 7 === 3 && this.showDiff) {
        return "warning-row";
      }
      // console.log(row);
      return "";
    },
    calcCondition(ori, key, map) {
      if (key === undefined) {
        return true;
      }
      console.log(map)
      console.log(ori)
      console.log(key)
      console.log(this.getMapping(map, ori))
      if (key === ori || key === this.getMapping(map, ori)) {
        return true;
      }
      return false;
    },
    downDiff() {
      const columns = [
        {
          header: "id",
          reg: "id",
          description: "机型id",
          key: "id",
          width: 32,
        },
        {
          header: "sku名称",
          reg: "skuName",
          description: "sku名称",
          key: "skuName",
          width: 64,
        },
        {
          header: "差异",
          reg: "diff",
          description: "差异",
          key: "diff",
          width: 64,
        },
      ];
      const diffTable = [];
      this.tableData.forEach((t) => {
        diffTable.push({
          id: t.id,
          skuName: t.skuName,
          diff: t.id % 7 === 3 ? "差异" : "无",
        });
      });
      downloadExcel("查询结果", [{ columns, dataList: diffTable }]);
    },
    download() {
      const columns = [
        {
          header: "id",
          reg: "id",
          description: "机型id",
          key: "id",
          width: 32,
        },
        {
          header: "sku名称",
          reg: "skuName",
          description: "sku名称",
          key: "skuName",
          width: 64,
        },
      ];
      downloadExcel("查询结果", [{ columns, dataList: this.tableData }]);
    },
    clearData() {
      this.reset();
      try {
        const api = `${config.host}/api/cleardata`;
        axios.post(api, {}).then((response) => {
          console.log(response);

        });
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    showDiffTable() {
      this.reset();
      this.showDiff = true;
      try {
        const api = `${config.host}/api/findcqccamissed`;
        axios.get(api).then((response) => {
          console.log(response);
          const data = response.data.results;

          const tempData = [];
          data.forEach((i) => {
            tempData.push({
              id: '',
              skuName: i.skuName,
            });
          });
          this.tableData = tempData;
        });
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    showNormalTable() {
      this.showDiff = false;
      const r = this.search();
      if (!r) {
        console.log(r);
        this.$alert('无结果', '查询', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${action}`
            });
          }
        });
      }
    },
    showDialog() {
      this.showDiff = false;
      const r = this.search();
      if (!r) {
        Dialog.alert({
          title: '查询结果',
          message: '无结果',
        }).then(() => {
          return
        });
      } else {
        Dialog.alert({
          title: '查询结果',
          message: this.searchModel,
        }).then(() => {
          // on close
        });
      }
    },
    search() {
      // if (!this.computers || this.computers.length === 0) {
      //   return;
      // }
      console.log(this.model)
      this.errorMsg = ""
      if (!this.model) {
        this.errorMsg = '*请选择平台'
        return
      }
      if (this.cpuKey === undefined) {
        this.errorMsg = '*请选择处理器'
        return
      }
      if (this.memKey === undefined) {
        this.errorMsg = '*请选择内存'
        return
      }
      if (this.hddKey === undefined) {
        this.errorMsg = '*请选择硬盘一'
        return
      }
      if (this.oddKey === undefined) {
        this.errorMsg = '*请选择硬盘二'
        return
      }

      if (this.graphicKey === undefined) {
        this.errorMsg = '*请选择独立显卡'
        return
      }

      console.log(this.computers.length);
      console.log(this.cpuKey);
      const result = this.computers.filter((c) => {

        const r = this.calcCondition(c.cpu, this.cpuKey, 'cpuMap') &&
          this.calcCondition(c.graphic, this.graphicKey, 'graphicMap') &&
          this.calcCondition(c.hdd, this.hddKey, 'hddMap') &&
          this.calcCondition(c.odd, this.oddKey, 'oddMap') &&
          this.calcCondition(c.memory, this.memKey, 'memoryMap')
        if (r) {
          console.log(c.skuName);
          console.log(c.odd);
          console.log(typeof c.odd);

          console.log(this.oddKey);
          console.log(typeof this.oddKey);
        }
        return r;
      });
      // this.$message.success(`共${result.length}条数据`);
      const tempData = [];
      result.forEach((i) => {
        tempData.push({
          id: i.id,
          skuName: i.skuName,
        });
      });
      console.log(tempData)
      // this.tableData = tempData;
      if (tempData.length > 0) {
        this.searchModel = tempData[0].skuName
        this.showSearchResult = true
        return true
      } else {
        return false
      }
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    reset() {
      this.$message.success("选项都已重置");
      this.errorMsg = ""
      this.showSearchResult = false
      this.model = undefined;
      this.cpuKey = undefined;
      this.memKey = undefined;
      this.hddKey = undefined;
      this.oddKey = undefined;
      this.graphicKey = undefined;
      this.cpuOptions = [];
      this.memOptions = [];
      this.hddOptions = [];
      this.oddOptions = [];
      this.graphicOptions = [];
      this.computers = [];
      this.tableData = [];
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onSubmit() {
      console.log("submit!");
      this.fileList = []
    },
    getOptions(type, theSet) {
      const result = [];
      theSet.forEach((item) => {
        result.push({ key: item, label: this.getMapping(type, item), text: this.getMapping(type, item), value: this.getMapping(type, item) });
      });
      return result;
    },
    setParams(computers) {
      this.cpus.clear();
      this.hdds.clear();
      this.odds.clear();
      this.mems.clear();
      this.graphics.clear();
      for (let index = 0; index < computers.length; index++) {
        const element = computers[index];
        this.cpus.add(element.cpu);
        this.hdds.add(element.hdd);
        this.odds.add(element.odd);
        this.mems.add(element.memory);
        this.graphics.add(element.graphic);
      }
      console.log(this.cpus);

      this.cpuOptions = this.getOptions("cpuMap", this.cpus);
      console.log(this.cpuOptions)
      this.hddOptions = this.getOptions("hddMap", this.hdds);
      this.oddOptions = this.getOptions("oddMap", this.odds);
      this.memOptions = this.getOptions("memoryMap", this.mems);
      this.graphicOptions = this.getOptions("graphicMap", this.graphics);

      this.$forceUpdate();
    },
    getMapping(type, key) {
      return this.modelMapping[type].find((i) => i.symbol == key).description;
    },
    setMapping(data) {
      const resultObject = data.reduce((acc, item) => {
        const { type, ...rest } = item;

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(rest);
        return acc;
      }, {});

      return resultObject;
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'iphone', 'ipad', 'ipod', 'windows phone'];

      // 检查是否包含移动设备关键词
      for (const keyword of mobileKeywords) {
        if (userAgent.includes(keyword)) {
          return true;
        }
      }

      // 如果没有匹配的关键词，则默认为非移动设备
      return false;
    }
  },
  watch: {
    cpuKey(val) {
      console.log(val);
    },
    hddKey(val) {
      console.log(val);
    },
    oddKey(val) {
      console.log(val);
    },
    memKey(val) {
      console.log(val);
    },
    graphicKey(val) {
      console.log(val);
    },
    async model(val) {
      console.log(val);
      console.log(val);
      this.cpuKey = undefined;
      this.memKey = undefined;
      this.hddKey = undefined;
      this.oddKey = undefined;
      this.graphicKey = undefined;
      if (val) {
        const apiMapping = `${config.host}/db/getmapping/${val}`;
        await axios.get(apiMapping).then(async (response) => {
          console.log(response);
          if (Array.isArray(response.data)) {
            this.modelMapping = this.setMapping(response.data);
            const api = `${config.host}/db/${val}`;
            await axios.get(api).then((response) => {
              // console.log(response);
              this.computers = response.data; //返回所有这个型号下的pc
              this.setParams(this.computers);
              // const data = response.data;
              // const ms = [];
              // for (let index = 0; index < data.length; index++) {
              //   const element = data[index];
              //   ms.push(element.name);
              // }
              // this.models = ms; //
            });
          }
          // this.computers = response.data; //返回所有这个型号下的pc
          // this.setParams(this.computers);
          // const data = response.data;
          // const ms = [];
          // for (let index = 0; index < data.length; index++) {
          //   const element = data[index];
          //   ms.push(element.name);
          // }
          // this.models = ms; //
        });
        console.log(this.modelMapping);
      }
    },
  },
};
</script>

<style>
.van-dropdown-item__content {
  position: absolute;
  max-height: 200px;
}

.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 11px;
}

.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 11px 40px;
  overflow: hidden;
  color: #323233;
  font-size: 11px;
  line-height: 24px;
  background-color: #fff;
}
</style>

<style scoped>
table {
  /* border: 1px solid #ddd; */
  border-spacing: 0;
  border-collapse: separate;
  text-indent: initial;
}

h2 {
  font-size: x-small;
}

table th {
  padding: 0px 10px !important;
  height: 48px;
  line-height: 48px !important;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  text-align: center;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.ddm {
  margin-bottom: 10px;
}

.table_selector {
  width: 520px;
}

.button_deepgrey {
  margin-right: 0px;
  width: 90px;
  background-color: #265a88;
  color: white;
}

.button_grey {
  margin-right: 0px;
  width: 90px;
  background-color: #e0e0e0;
  font-size: 16;
}

.th1 {
  border: 1px solid #ddd;

  width: 160px;
}

.th2 {
  border: 1px solid #ddd;
  width: 540px;
}

.row1 {
  border: 1px solid #ddd;

  background-color: aliceblue;
  vertical-align: bottom;
  height: 48px;
  padding: 0px 10px !important;
  height: 48px;
  line-height: 48px !important;
}

.row2 {
  border: 1px solid #ddd;

  vertical-align: bottom;
  height: 48px;
  padding: 0px 10px !important;
  height: 48px;
  line-height: 48px !important;
}

.table_two {
  margin-left: 20px;
  border-collapse: collapse;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  /* border-radius: 4px; */
  min-height: 36px;
  border: grey 0.2px solid;
  background-color: lightcyan;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-upload {
  text-align: left;
}

.el-upload>>>ul {
  max-height: 20vh;
  overflow: scroll;
}

.group__title {
  line-height: 120px;
  font-size: 28px;
  color: #0096d6;
  font-family: "Microsoft Yahei";
}

.title_old {
  font-size: 20px;
  color: #0096d6;
  margin-bottom: 12px;
  margin-left: 12px;
  /* border-left: 3px solid #0096d6; */
  padding-left: 10px;
  font-weight: bold;
  width: 140px;
  text-align: left;
}

.title_old::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 30px;
  vertical-align: top;
  background: #1989fa;
  margin-right: 10px;
}

.filterTable {
  width: 700px;
  margin-top: 10px;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.errorInfo {
  float: right;
  margin-right: 10px;
  color: #c9302c;
  margin-top: 5px;
}

/* .table .table__header th {
  height: 29px;
  font-size: 14px;
  line-height: 29px;
  padding: 5px 0;
}

.table .table__content td {
  height: 20px;
  font-size: 14px;
  line-height: 1;
  padding: 3px 0;
} */
</style>
