<template>
  <div v-if="!isMobile">
    <div
      v-loading="loading"
      element-loading-text="登录中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="login-container"
    >
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <div class="title-container" style="margin-bottom: 50px">
          <h3 class="title">外贸跟单公益培训成绩查询</h3>
        </div>

        <el-form-item prop="password">
          <div class="title_old">请输入您的密码：</div>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <!-- <svg-icon
            :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
          /> -->
          </span>
        </el-form-item>
        <el-form-item prop="username">
          <div class="title_old">
            请输入您的身份证(或报名时使用的其他证件)号：
          </div>

          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="证件号"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <div>
          <el-button
            type="primary"
            style="width: 100%; margin-bottom: 20px; margin-top: 30px"
            @click.native.prevent="handleLogin"
            >成绩查询</el-button
          >
        </div>
      </el-form>
    </div>
    <div style="width: 360px" class="center-div">
      <el-table :data="tableData" width="360" class="noline">
        <el-table-column prop="key" label="" width="180"> </el-table-column>
        <el-table-column prop="value" label="" width="180"> </el-table-column>
      </el-table>
    </div>
  </div>
  <div v-else class="mt-26">
    <span v-text="notification"></span>
  </div>
</template>

<script>
// 引入去除空格工具
// import { validUsername } from "@/utils/validate";
import { config } from '../config.js'
import axios from 'axios'

export default {
  name: 'LoginPage',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('证件号不能为空！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error('密码最少为4位字符！'))
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      isMobile: false,
      showLogin: false,
      notification: '验证中',
      // 头像状态
      TxStatus: true,
      loginForm: {
        username: '',
        password: '',
      },
      //登录规则
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername },
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
    }
  },
  mounted() {
    this.isMobile = this.isMobileDevice()
    this.showLogin = true
    console.log(sessionStorage.getItem('showLogin'))
    console.log(sessionStorage.getItem('showLogin') === '1')
    console.log('this.isAdmin')
    // if (this.isMobile) {
    //   this.wxminilogin({ code: sessionStorage.getItem('authcode') })
    //   console.log(sessionStorage.getItem('authcode'))
    // }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  methods: {
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase()
      const mobileKeywords = [
        'android',
        'iphone',
        'ipad',
        'ipod',
        'windows phone',
      ]
      console.log(userAgent)
      // 检查是否包含移动设备关键词
      for (const keyword of mobileKeywords) {
        if (userAgent.includes(keyword)) {
          return true
        }
      }

      // 如果没有匹配的关键词，则默认为非移动设备
      return false
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    checkScore(data) {
      this.notification = '登录中'

      this.url = config.host + '/api/score'
      const apiScore = `${config.host}/db/getscore/${data.id}`

      console.log('🚀 ~ checkScore ~ apiScore:', apiScore)
      try {
        axios.get(apiScore).then((response) => {
          console.log(response)
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              const d = response.data[0]
              this.tableData.push({ key: '姓名', value: d.name })
              this.tableData.push({ key: '性别', value: d.sex })
              this.tableData.push({ key: '基础成绩', value: d.jccj })
              this.tableData.push({ key: '实操成绩', value: d.swcj })
            }
          }
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },
    // 登录业务
    handleLogin() {
      console.log(this.loginForm.password)
      this.tableData = []
      if (this.loginForm.password == '123456') {
        if (!this.loginForm.username) {
          this.$message.warning(`缺少证件号`)
        } else {
          this.checkScore({ id: this.loginForm.username })
        }
      } else {
        this.$message.warning(`密码错误`)
      }
    },
    // 注册业务
    register() {
      console.log('123')
      this.$router.push({ name: 'register' })
    },
  },
}
</script>

<style scoped>
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: #fff;
  }
}

.login-container .el-input {
  display: inline-block;
  height: 47px;
  width: 85%;
}

.login-container .el-input input {
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  padding: 12px 5px 12px 15px;
  color: #fff;
  height: 47px;
  caret-color: #fff;
}

.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #283443 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.login-container .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  color: #454545;
  margin: 0px;
}

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  /* background: url(~@/assets/bg0.jpg); */
  background-size: 100% 100%;
}

.login-container .avatar-box {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #409eff;
  box-shadow: 0 0 10px #409eff;
  position: relative;
  bottom: 20px;
}

.login-container .avatar-box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.login-container .login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.login-container .tips {
  font-size: 18px;
  text-align: center;
  color: #000;
  margin-bottom: 10px;
}

.login-container .svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 55px;
  display: inline-block;
}

.login-container .title-container {
  position: relative;
}

.login-container .title-container .title {
  font-size: 26px;
  color: #000;
  margin: 0px auto 3px auto;
  text-align: center;
  font-weight: 500;
}

.login-container .show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}
.center-div {
  width: 300px; /* 设置宽度 */
  margin-left: auto; /* 左边距为自动 */
  margin-right: auto; /* 右边距为自动 */
}

.noline .el-table th.el-table__cell.is-leaf {
  border-bottom: 0px solid transparent;
}
</style>
