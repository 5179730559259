<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    this.init()
    const favicon = document.getElementById('favicon')
    console.log(favicon)
  },
  methods: {
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase()
      const mobileKeywords = [
        'android',
        'iphone',
        'ipad',
        'ipod',
        'windows phone',
      ]

      // 检查是否包含移动设备关键词
      for (const keyword of mobileKeywords) {
        if (userAgent.includes(keyword)) {
          return true
        }
      }

      // 如果没有匹配的关键词，则默认为非移动设备
      return false
    },
    isWxMiniCodeWebviewEnv() {
      console.log(navigator.userAgent)

      return (
        navigator.userAgent.match(/miniprogram/i) ||
        window.__wxjs_environment == 'miniprogram'
      )
    },

    init() {
      const urlParams = new URLSearchParams(window.location.search)
      console.log(urlParams.toString())
      console.log('==')
      const result = {}
      for (const [key, value] of urlParams.entries()) {
        // each 'entry' is a [key, value] tupple
        result[key] = value
      }
      console.log(result)
      if (result && result.isadmin == 'true') {
        sessionStorage.setItem('showLogin', '1')
      } else {
        sessionStorage.setItem('showLogin', '0')
      }
      if (result.channel === 'mp') {
        sessionStorage.setItem('authcode', result.authcode)
      }
      // const func = urlParams.get("func");
      console.log(this.isWxMiniCodeWebviewEnv())
      if (this.isMobileDevice()) {
        this.$router
          .replace({
            name: 'Score',
          })
          .catch(() => {})
      } else {
        this.$router
          .replace({
            name: 'Score',
          })
          .catch(() => {})
      }
    },
  },
}
</script>

<style>
@import 'style/common.css';
@import 'style/style.css';
@import 'style/colors.css';
@import 'style/elStyle.css';
@import 'style/table.css';
@import 'style/modal.css';
@import 'style/layout.css';
@import 'style/size.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
