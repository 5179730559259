import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAdmin: false,

    //customerDetailActiveTab:''
  },
  mutations: {
    setIsAdmin(state, payload) {
      state.isAdmin = payload
    },
  },
})
