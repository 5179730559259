const ExcelJS = require('exceljs')
function downloadFile(file, fileName, fileType, callback) { // 下载文件
  if (!file) {
    return
  }
  let str = fileName
  fileType && (str += `.${fileType}`)
  const a = window.document.createElement('a')
  a.download = str
  a.href = file
  a.dispatchEvent(new window.MouseEvent('click'))
  callback && callback()
}

function downloadBlob(blob, fileName, fileType) { // blob数据转可下载文件
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = e => {
    downloadFile(e.target.result, fileName, fileType)
  }
}
async function downloadExcel(fileName, worksheets = []) { // 下载excel
  const workbook = new ExcelJS.Workbook()
  worksheets.forEach(v => {
    const sheetName = v.worksheetName || '工作表'
    const worksheet = workbook.addWorksheet(sheetName)
    worksheet.columns = v.columns
    worksheet.addRows(v.dataList.map(data => v.columns.reduce((obj, column) => {
      obj[column.key] = data[column.key]
      return obj
    }, {})))
  })
  try {
    const buffer = await workbook.xlsx.writeBuffer()
    downloadBlob(new Blob([buffer]), fileName, 'xlsx')
  } catch (e) {
    console.error(e)
  }
}

export {
  downloadExcel,
}